import BaseProxy from './base.proxy';

class ProviderProxy extends BaseProxy {
  providers(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/provider?${queryString}`);
  }
  provider(id) {
    return this.get(`/provider/${id}`);
  }
  providerSetting(id) {
    return this.get(`/provider/${id}/setting`);
  }
  changeStatus(id, data) {
    return this.put(`/admin/provider/${id}/status`, data);
  }
  changeCategories(id, data) {
    return this.put(`/admin/provider/${id}/categories`, data);
  }
  updateProvider(id, data) {
    return this.put(`/admin/provider/${id}`, data);
  }
  addDocument(id, data) {
    return this.post(`/admin/provider/${id}/document`, data);
  }
  addPhoto(id, data) {
    return this.post(`/admin/provider/${id}/photo`, data);
  }
  deleteDocument(id, documentId) {
    return this.delete(`/admin/provider/${id}/document/${documentId}`);
  }
  deletePhoto(id, photoId) {
    return this.delete(`/admin/provider/${id}/photo/${photoId}`);
  }
  getDocuments(id) {
    return this.get(`/admin/provider/${id}/document`);
  }
  getServices(id) {
    return this.get(`/admin/provider/${id}/post-service`);
  }
  linkStripeAccount(id, data) {
    return this.post(`/admin/provider/${id}/stripe/link`, data);
  }
  getEarnings(id, period) {
    return this.get(`/admin/provider/${id}/earning/${period}`);
  }
  getEarningPayouts(id) {
    return this.get(`/admin/provider/${id}/payout`);
  }
  getRequests(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/provider/request?${queryString}`);
  }
  getProviderOwed(query) {
    let queryString = new URLSearchParams(query).toString();
    return this.get(`/admin/provider/owed?${queryString}`);
  }
  getProviderOwedHistory(id) {
    return this.get(`/admin/provider/${id}/owed/history`);
  }
  getMetricProvider() {
    return this.get(`/admin/provider/metrics`);
  }
  getPayoutBalance(type) {
    return this.get(`/admin/provider/payout/balance?balance_type=${type}`);
  }
  adminChangeStatusPostService(id, subcategoryId, status) {
    return this.put(
      `/admin/provider/${id}/post-service/${status}/${subcategoryId}`
    );
  }
}

export default new ProviderProxy();
