function convertBase64ToFile(base64, fileName, fileType) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], { type: fileType });
  return new File([newBlob], fileName);
}

function hashCode(str) {
  if (!str) return 0;
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

module.exports = {
  ProviderBadge: (status) => {
    switch (status) {
      case 'active':
        return 'info';
      case 'approved':
        return 'success';
      case 'pending':
        return 'primary';
      case 'rejected':
        return 'dark';
      case 'suspended':
        return 'danger';
      case 'banned':
        return 'secondary';
      case 'deleted':
        return 'dark';
      default:
        'secondary';
    }
  },
  UserBadge: (status) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'primary';
      case 'banned':
        return 'danger';
      case 'deleted':
        return 'dark';
      default:
        'secondary';
    }
  },
  CategoryBadge: (status) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'inactive':
        return 'primary';
      case 'banned':
        return 'danger';
      default:
        'secondary';
    }
  },
  TaskQueueBadge: (status) => {
    switch (status) {
      case 'completed':
        return 'success';
      case 'processing':
        return 'primary';
      case 'cancelled':
        return 'dark';
      default:
        'secondary';
    }
  },
  BookingBadge: (status) => {
    switch (status) {
      case 'ongoing':
      case 'quoted':
      case 'on-site':
        return 'warning';
      case 'completed':
        return 'success';
      case 'cancelled':
        return 'dark';
      case 'pending':
        return 'primary';
      case 'expired':
        return 'danger';
      case 'rejected':
        return 'light';
      case 'reassigned':
        return 'danger';
      default:
        'secondary';
    }
  },
  ValidateEmail: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  CompressImageFromFile: (file, { scale = 100, quality = 100 }) => {
    const canvas = document.createElement('canvas');
    let img = new Image();

    return new Promise((resolve, reject) => {
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        let width = (img.width * scale) / 100;
        let height = (img.height * scale) / 100;

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        let fileType = 'image/jpeg';
        let base64 = ctx.canvas.toDataURL(
          fileType,
          quality ? quality / 100 : 1
        );
        let fileName = file.name;
        let lastDot = fileName.lastIndexOf('.');
        fileName = fileName.substr(0, lastDot) + '.jpeg';
        return resolve({
          base64,
          fileName,
          file: convertBase64ToFile(base64, fileName, fileType),
          type: fileType,
        });
      };
      img.onerror = function (err) {
        reject(err);
      };
    });
  },

  PickColorFromString: (str) => {
    return `hsl(${hashCode(str) % 360}, 100%, 50%)`;
  },
  PermissionList: () => {
    return [
      {
        name: 'User internal',
        value: 'user_internal',
      },
      {
        name: 'Category',
        value: 'category',
      },
      {
        name: 'Finance',
        value: 'finance',
      },
      {
        name: 'Consumer',
        value: 'consumer',
      },
      {
        name: 'Provider',
        value: 'provider',
      },
      {
        name: 'Booking',
        value: 'booking',
      },
      {
        name: 'Announcement',
        value: 'announcement',
      },
    ];
  },
  FormatCurrency100: (value, currency = 'USD') => {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    });
    return formatter.format(value / 100);
  },
  Capitalize: (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  },
};
